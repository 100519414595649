import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Dialog (Modal)" description="An accessible modal dialog component for React" mdxType="SEO" />
    <h1 {...{
      "id": "dialog-modal"
    }}>{`Dialog (Modal)`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#dialog" mdxType="TOCLink">Dialog</TOCLink>
    <TOCLink href="#dialogoverlay" mdxType="TOCLink">DialogOverlay</TOCLink>
    <TOCLink href="#dialogcontent" mdxType="TOCLink">DialogContent</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/dialog"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/dialog`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#dialog_modal`}</a></li>
    </ul>
    <p>{`An accessible dialog or "modal" window.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Open Dialog</button>

      <Dialog isOpen={showDialog} onDismiss={close}>
        <button className="close-button" onClick={close}>
          <VisuallyHidden>Close</VisuallyHidden>
          <span aria-hidden>×</span>
        </button>
        <p>Hello there. I am a dialog</p>
      </Dialog>
    </div>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/dialog`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/dialog`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/dialog
# or
yarn add @reach/dialog
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { Dialog, DialogOverlay, DialogContent } from "@reach/dialog";
import "@reach/dialog/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "dialog"
    }}>{`Dialog`}</h3>
    <p>{`High-level component to render a modal dialog window over the top of the page (or another dialog).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Dialog>
  <p>Some Content</p>
</Dialog>
`}</code></pre>
    <h4 {...{
      "id": "dialog-props"
    }}>{`Dialog Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-allowpinchzoom"
            }}><inlineCode parentName="a">{`allowPinchZoom`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-initialfocusref"
            }}><inlineCode parentName="a">{`initialFocusRef`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-isopen"
            }}><inlineCode parentName="a">{`isOpen`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-ondismiss"
            }}><inlineCode parentName="a">{`onDismiss`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "dialog-div-props"
    }}>{`Dialog div props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{` element, which in turn is spread onto the underlying `}<inlineCode parentName="p">{`div[data-reach-dialog-content]`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      <Dialog style={{ color: "red" }} isOpen={showDialog} onDismiss={close}>
        <p>My text is red because the style prop got applied to the div</p>
        <button onClick={close}>Okay</button>
      </Dialog>
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "dialog-allowpinchzoom"
    }}>{`Dialog allowPinchZoom`}</h5>
    <p><inlineCode parentName="p">{`allowPinchZoom?: boolean`}</inlineCode></p>
    <p>{`Handle zoom/pinch gestures on iOS devices when scroll locking is enabled. Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Dialog allowPinchZoom={true}>
  <p>
    I will allow zoom and pinch gestures on iOS devices even when scrolling is
    locked!
  </p>
</Dialog>
`}</code></pre>
    <h5 {...{
      "id": "dialog-children"
    }}>{`Dialog children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Accepts any renderable content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Dialog>
  <p>Anything you want, you got it.</p>
</Dialog>
`}</code></pre>
    <h5 {...{
      "id": "dialog-initialfocusref"
    }}>{`Dialog initialFocusRef`}</h5>
    <p><inlineCode parentName="p">{`initialFocusRef?: React.RefObject<any>`}</inlineCode></p>
    <p>{`By default the first focusable element will receive focus when the dialog opens but you can provide a ref to focus instead.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const buttonRef = React.useRef();
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      <Dialog isOpen={showDialog} initialFocusRef={buttonRef} onDismiss={close}>
        <p>Pass the button ref to Dialog and the button.</p>
        <button onClick={close}>Not me</button>
        <button ref={buttonRef} onClick={close}>
          Got me!
        </button>
      </Dialog>
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "dialog-isopen"
    }}>{`Dialog isOpen`}</h5>
    <p><inlineCode parentName="p">{`isOpen?: boolean`}</inlineCode></p>
    <p>{`Controls whether or not the dialog is open.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Dialog isOpen={true}>
  <p>I will be open</p>
</Dialog>

<Dialog isOpen={false}>
  <p>I will be closed</p>
</Dialog>
`}</code></pre>
    <p>{`If you'd rather not have the dialog always rendered, you can put a guard in front of it and only render when it should be open. In this case you don’t need the `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` prop at all.`}</p>
    <p>{`Note, however, that the dialog will not render to the DOM when `}<inlineCode parentName="p">{`isOpen={false}`}</inlineCode>{`, but you may want to save on the number of elements created in your render function. You should probably do this when your dialog contains a lot of elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      {showDialog && (
        <Dialog onDismiss={close}>
          <p>
            I don’t use <code>isOpen</code>, I just render when I should and not
            when I shouldn’t.
          </p>
          <button onClick={close}>Okay</button>
        </Dialog>
      )}
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "dialog-ondismiss"
    }}>{`Dialog onDismiss`}</h5>
    <p><inlineCode parentName="p">{`onDismiss?: (event?: React.SyntheticEvent) => void`}</inlineCode></p>
    <p>{`This function is called whenever the user hits "Escape" or clicks outside the dialog. `}<em parentName="p">{`It's important to close the dialog `}<inlineCode parentName="em">{`onDismiss`}</inlineCode></em>{` as seen in all the demos on this page.`}</p>
    <p>{`The only time you shouldn't close the dialog on dismiss is when the dialog requires a choice and none of them are "cancel". For example, perhaps two records need to be merged and the user needs to pick the surviving record. Neither choice is less destructive than the other, in these cases you may want to alert the user they need to a make a choice on dismiss instead of closing the dialog.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      <Dialog isOpen={showDialog} onDismiss={close}>
        <p>
          It is your job to close this with state when the user clicks outside
          or presses escape.
        </p>
        <button onClick={close}>Okay</button>
      </Dialog>
    </div>
  );
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const [showWarning, setShowWarning] = React.useState(false);
  const open = () => {
    setShowDialog(true);
    setShowWarning(false);
  };
  const close = () => setShowDialog(false);
  const dismiss = () => setShowWarning(true);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      <Dialog isOpen={showDialog} onDismiss={dismiss}>
        {showWarning && (
          <p style={{ color: "red" }}>You must make a choice, sorry :(</p>
        )}
        <p>Which router should survive the merge?</p>
        <button onClick={close}>React Router</button>{" "}
        <button onClick={close}>@reach/router</button>
      </Dialog>
    </div>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "dialogoverlay"
    }}>{`DialogOverlay`}</h3>
    <p>{`Low-level component if you need more control over the styles or rendering of the dialog overlay.`}</p>
    <p>{`Note: You must render a `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{` inside.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      <DialogOverlay
        style={{ background: "hsla(0, 100%, 100%, 0.9)" }}
        isOpen={showDialog}
        onDismiss={close}
      >
        <DialogContent
          style={{ boxShadow: "0px 10px 50px hsla(0, 0%, 0%, 0.33)" }}
        >
          <p>
            The overlay styles are a white fade instead of the default black
            fade.
          </p>
          <button onClick={close}>Very nice.</button>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "dialogoverlay-css-selectors"
    }}>{`DialogOverlay CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the overlay:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-dialog-overlay] {
}
`}</code></pre>
    <h4 {...{
      "id": "dialogoverlay-props"
    }}>{`DialogOverlay Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-allowpinchzoom"
            }}><inlineCode parentName="a">{`allowPinchZoom`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-dangerouslybypassfocuslock"
            }}><inlineCode parentName="a">{`dangerouslyBypassFocusLock`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialog-dangerouslybypassscrolllock"
            }}><inlineCode parentName="a">{`dangerouslyBypassScrollLock`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-initialfocusref"
            }}><inlineCode parentName="a">{`initialFocusRef`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ref`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-isopen"
            }}><inlineCode parentName="a">{`isOpen`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`bool`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogoverlay-ondismiss"
            }}><inlineCode parentName="a">{`onDismiss`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "dialogoverlay-div-props"
    }}>{`DialogOverlay div props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<DialogOverlay className="light-modal">
  <p>The underlying element will receive a class</p>
</DialogOverlay>
`}</code></pre>
    <h5 {...{
      "id": "dialogoverlay-allowpinchzoom"
    }}>{`DialogOverlay allowPinchZoom`}</h5>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#dialog-allowpinchzoom"
      }}>{`Dialog `}<inlineCode parentName="a">{`allowPinchZoom`}</inlineCode></a>{`.`}</p>
    <h5 {...{
      "id": "dialogoverlay-children"
    }}>{`DialogOverlay children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Should be a `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<DialogOverlay>
  <DialogContent>Hey!</DialogContent>
</DialogOverlay>
`}</code></pre>
    <h5 {...{
      "id": "dialogoverlay-dangerouslybypassfocuslock"
    }}>{`DialogOverlay dangerouslyBypassFocusLock`}</h5>
    <p><inlineCode parentName="p">{`dangerouslyBypassFocusLock?: boolean`}</inlineCode></p>
    <p>{`By default the dialog locks the focus inside it. Normally this is what you want. This prop is provided so that this feature can be disabled. This, however, is strongly discouraged.`}</p>
    <p>{`The reason it is provided is not to disable the focus lock entirely. Rather, there are certain situations where you may need more control on how the focus lock works. This should be complemented by setting up a focus lock yourself that would allow more flexibility for your specific use case.`}</p>
    <p>{`If you do set this prop to `}<inlineCode parentName="p">{`true`}</inlineCode>{`, make sure you set up your own `}<inlineCode parentName="p">{`FocusLock`}</inlineCode>{` component. You can likely use `}<a parentName="p" {...{
        "href": "https://github.com/theKashey/react-focus-lock"
      }}><inlineCode parentName="a">{`react-focus-lock`}</inlineCode></a>{`, which is what Reach UI uses internally by default. It has various settings to allow more customization, but it takes care of a lot of hard work that you probably don't want or need to do.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function DialogWithCustomFocusLock() {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      <DialogOverlay
        isOpen={showDialog}
        onDismiss={close}
        dangerouslyBypassFocusLock
      >
        <MyCustomFocusLock>
          <DialogContent aria-label="Announcement">
            This is an important announcement!
          </DialogContent>
        </MyCustomFocusLock>
      </DialogOverlay>
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "dialogoverlay-dangerouslybypassscrolllock"
    }}>{`DialogOverlay dangerouslyBypassScrollLock`}</h5>
    <p><inlineCode parentName="p">{`dangerouslyBypassScrollLock?: boolean`}</inlineCode></p>
    <p>{`By default the dialog locks scrolling with `}<a parentName="p" {...{
        "href": "https://github.com/theKashey/react-remove-scroll"
      }}><inlineCode parentName="a">{`react-remove-scroll`}</inlineCode></a>{`, which also injects some styles on the body element to remove the scrollbar while maintaining its gap to prevent jank when the dialog's open state is toggled. This is almost always what you want in a dialog, but in some cases you may have the need to customize this behavior further.`}</p>
    <p>{`This prop will disable `}<inlineCode parentName="p">{`react-remove-scroll`}</inlineCode>{` and allow you to compose your own scroll lock component to meet your needs. Like the `}<inlineCode parentName="p">{`dangerouslyBypassFocusLock`}</inlineCode>{` prop, this is generally discouraged and should only be used if a proper fallback for managing scroll behavior is provided.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function DialogWithCustomScrollLock() {
  const [showDialog, setShowDialog] = React.useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <div>
      <button onClick={open}>Show Dialog</button>
      <DialogOverlay
        isOpen={showDialog}
        onDismiss={close}
        dangerouslyBypassScrollLock
      >
        <MyCustomScrollLock>
          <DialogContent aria-label="Announcement">
            This is an important announcement!
          </DialogContent>
        </MyCustomScrollLock>
      </DialogOverlay>
    </div>
  );
}
`}</code></pre>
    <h5 {...{
      "id": "dialogoverlay-initialfocusref"
    }}>{`DialogOverlay initialFocusRef`}</h5>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#dialog-initialfocusref"
      }}>{`Dialog `}<inlineCode parentName="a">{`initialFocusRef`}</inlineCode></a>{`.`}</p>
    <h5 {...{
      "id": "dialogoverlay-isopen"
    }}>{`DialogOverlay isOpen`}</h5>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#dialog-isopen"
      }}>{`Dialog `}<inlineCode parentName="a">{`isOpen`}</inlineCode></a>{`.`}</p>
    <h5 {...{
      "id": "dialogoverlay-ondismiss"
    }}>{`DialogOverlay onDismiss`}</h5>
    <p>{`Same as `}<a parentName="p" {...{
        "href": "#dialog-ondismiss"
      }}>{`Dialog `}<inlineCode parentName="a">{`onDismiss`}</inlineCode></a>{`.`}</p>
    <h3 {...{
      "id": "dialogcontent"
    }}>{`DialogContent`}</h3>
    <p>{`Low-level component if you need more control over the styles or rendering of the dialog content.`}</p>
    <p>{`Note: Must be a child of `}<inlineCode parentName="p">{`DialogOverlay`}</inlineCode>{`.`}</p>
    <p>{`Note: You only need to use this when you are also styling `}<inlineCode parentName="p">{`DialogOverlay`}</inlineCode>{`, otherwise you can use the high-level `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` component and pass the props to it. Any props passed to `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` component (besides `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` and `}<inlineCode parentName="p">{`onDismiss`}</inlineCode>{`) will be spread onto `}<inlineCode parentName="p">{`DialogContent`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example(props) {
  const [showDialog, setShowDialog] = React.useState(false);
  const buttonRef = React.useRef();
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  return (
    <div>
      <button onClick={open}>Show Dialog</button>

      <DialogOverlay isOpen={showDialog} onDismiss={close}>
        <DialogContent
          style={{
            border: "solid 5px hsla(0, 0%, 0%, 0.5)",
            borderRadius: "10px",
          }}
        >
          <p>I have a nice border now.</p>
          <p>
            Note that we could have used the simpler <code>Dialog</code>{" "}
            instead.
          </p>
          <button onClick={close}>Got it.</button>
        </DialogContent>
      </DialogOverlay>
    </div>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "dialogcontent-css-selectors"
    }}>{`DialogContent CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <p>{`Use the following CSS to target the overlay:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-dialog-content] {
}
`}</code></pre>
    <h4 {...{
      "id": "dialogcontent-props"
    }}>{`DialogContent Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogcontent-div-props"
            }}><inlineCode parentName="a">{`div`}</inlineCode>{` props`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#dialogcontent-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "dialogcontent-div-props"
    }}>{`DialogContent div props`}</h5>
    <p>{`Any props not listed above will be spread onto the underlying `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<DialogContent className="nice-border">
  <p>The underlying element will receive a class</p>
</DialogContent>
`}</code></pre>
    <h5 {...{
      "id": "dialogcontent-children"
    }}>{`DialogContent children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p>{`Accepts any renderable content.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<DialogContent>
  <p>Anything you want, you got it.</p>
</DialogContent>
`}</code></pre>
    <h2 {...{
      "id": "animation-example"
    }}>{`Animation Example`}</h2>
    <p>{`If you'd like to animate the content, give `}<a parentName="p" {...{
        "href": "https://github.com/drcmda/react-spring"
      }}>{`React Spring`}</a>{` a shot.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  const AnimatedDialogOverlay = animated(DialogOverlay);
  const AnimatedDialogContent = animated(DialogContent);

  function Example(props) {
    const [showDialog, setShowDialog] = React.useState(false);
    const transitions = useTransition(showDialog, {
      from: { opacity: 0, y: -10 },
      enter: { opacity: 1, y: 0 },
      leave: { opacity: 0, y: 10 },
    });
    return (
      <div>
        <button onClick={() => setShowDialog(true)}>Show Dialog</button>
        {transitions(
          (styles, item) =>
            item && (
              <AnimatedDialogOverlay style={{ opacity: styles.opacity }}>
                <AnimatedDialogContent
                  aria-labelledby="dialog-title"
                  style={{
                    transform: styles.y.to(
                      (value) => \`translate3d(0px, \${value}px, 0px)\`
                    ),
                    border: "4px solid hsla(0, 0%, 0%, 0.5)",
                    borderRadius: 10,
                  }}
                >
                  <button onClick={() => setShowDialog(false)}>
                    Close Dialog
                  </button>
                  <h2 id="dialog-title">Animated Dialog</h2>
                  <p>React Spring makes it too easy!</p>
                  <input type="text" />
                  <br />
                  <input type="text" />
                  <button>Ayyyyyy</button>
                </AnimatedDialogContent>
              </AnimatedDialogOverlay>
            )
        )}
      </div>
    );
  }
  return <Example />;
})();
`}</code></pre>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <h3 {...{
      "id": "tabbable-elements"
    }}>{`Tabbable Elements`}</h3>
    <p>{`It is recommended to have at least one tabbable element in the dialog content. Ideally the first element in the dialog is a close button. If no tabbable elements are found, the dialog content element itself will receive focus.`}</p>
    <h3 {...{
      "id": "labeling"
    }}>{`Labeling`}</h3>
    <p>{`As with many other complex UI components, a dialog needs to be properly labeled to provide context for users with assistive technology such as screen readers. If a dialog is announced to the user without a label, it can be confusing and difficult to navigate.`}</p>
    <p>{`There are two general approaches to labeling UI components on the web: `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` and `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`. If the text is visible on screen, you should provide the label's HTML element with a unique `}<inlineCode parentName="p">{`id`}</inlineCode>{` attribute. That `}<inlineCode parentName="p">{`id`}</inlineCode>{` value is then given to an `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attribute (or in React, an `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` prop) on the `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{`. With this context, the screen reader will announce whatever text is nested inside that ID'd markup as the title for the `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{`.`}</p>
    <p>{`Alternatively, a design may not include a visible label on the screen, but this context is still important for non-sighted users. In this case, you need to provide an `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` prop to your `}<inlineCode parentName="p">{`Dialog`}</inlineCode>{` instead.`}</p>
    <h4 {...{
      "id": "with-aria-label"
    }}>{`With aria-label`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example(props) {
  return (
    <Dialog aria-label="Warning about next steps">
      <p>This might take a while! Are you sure?</p>
      <button>Go Forward</button> <button>Go Back</button>
    </Dialog>
  );
}
`}</code></pre>
    <h4 {...{
      "id": "with-aria-labelledby"
    }}>{`With aria-labelledby`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`function Example(props) {
  const labelId = \`label--\${useId(props.id)}\`;
  return (
    <Dialog aria-labelledby={labelId}>
      <h1 id={labelId}>Next Steps</h1>
      <p>Follow these steps carefully!</p>
      <button>Go Forward</button> <button>Go Back</button>
    </Dialog>
  );
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`NOTE: American users may see `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` and suspect a typo. In some regions (including the UK and Great Britain), the word is indeed spelled `}<inlineCode parentName="p">{`labelled`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`labeled`}</inlineCode>{`. The correct HTML attribute is `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`.`}</p>
    </blockquote>
    <p>{`For more details, see the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria/#aria-label"
      }}>{`W3C recommendations for `}<inlineCode parentName="a">{`aria-label`}</inlineCode></a>{`.`}</p>
    <h3 {...{
      "id": "using-aria-hidden-for-other-elements"
    }}>{`Using aria-hidden for Other Elements`}</h3>
    <p>{`The aria role "dialog" has been problematic in the past with the virtual cursor, effectively hiding a lot of dialog content from screen reader users. Instead, Dialog will set `}<inlineCode parentName="p">{`aria-hidden`}</inlineCode>{` on all nodes at the `}<inlineCode parentName="p">{`document.body`}</inlineCode>{` root except for the currently active dialog. This traps the virtual cursor inside the dialog.`}</p>
    <p>{`This is a little unusual for a React component to traverse the DOM that it isn't a part of. Care has been taken to restore the manipulated attributes back to their original values.`}</p>
    <h3 {...{
      "id": "keyboard-accessibility"
    }}>{`Keyboard Accessibility`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`key`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`action`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><kbd>{`Escape`}</kbd></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Dismisses the dialog (if the app allows)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "z-index-wars"
    }}>{`Z-Index Wars`}</h2>
    <p>{`Dialog does not set a `}<inlineCode parentName="p">{`z-index`}</inlineCode>{`; it depends on the DOM order to be on top of the rest of the app (the overlay is inserted at the end of the document when it is opened). If your app is already battling in the Z-Index Wars, be sure to add a `}<inlineCode parentName="p">{`z-index`}</inlineCode>{` to the `}<inlineCode parentName="p">{`<Dialog>`}</inlineCode>{` or `}<inlineCode parentName="p">{`<DialogOverlay>`}</inlineCode>{` that you render.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      